import React from "react";
import "./about.css";
import ME from "../../assets/profile_pic.png";
import HeaderSocials from "./HeaderSocials";

const About = () => {
  return (
    <section id="about">
      <div className="about_main">
        <div className="img_social">
          <div className="me">
            <img src={ME} alt="me" />
          </div>
          <HeaderSocials />
        </div>

        <div>
          <h5>Get To Know</h5>
          <h2>About Me</h2>
          <p className="about">
            Experienced and innovative web developer with over 3 years of proven
            expertise in creating user-friendly applications. Passionate about
            delivering optimized solutions and seeking challenging opportunities
            for continuous growth. Eager to contribute skills and drive
            innovation in a dynamic team environment.
          </p>
          <h2>Experience</h2>
          <p className="about" style={{ margin: "20px auto 2px auto" }}>
            Frontend Developer
          </p>
          <p className="about_inner">
            {" "}
            <a href="https://www.oodlestechnologies.com/" target="_blank">
              Oodles Technologies - Gurugram (Haryana)
            </a>
          </p>
          <p className="about_inner">May 2022 - Present</p>
          <p className="about_inner">
            Experienced in ReactJS & Redux for scalable frontend solutions.
            Proficient in JavaScript with a focus on interactive web
            applications. Skilled in CSS, creating visually appealing web
            designs. Experienced in Web3 blockchain integration.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
